<template>
  <div class="step2">
    <div class="send_success">
      <img :src="complete" class="complete-img">
      <h3>注册完成</h3>
      <p>{{sec}}s后跳转原页面</p>
      <div class="next-btn submit">
        <van-button round block
                    type="info"
                    @click="toIndex"
        >
          返回首页
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import complete from '@/assets/images/account/complete.png'
export default {
  name: 'step1',
  data () {
    return {
      complete,
      timer: null,
      sec: 5
    }
  },
  methods: {
    toIndex () {
      this.$router.replace('/')
    }
  },
  mounted () {
    this.timer = this.timer || setInterval(() => {
      if (this.sec === 1) {
        clearTimeout(this.timer)
        this.timer = null
        this.$emit('finish')
        return
      }
      this.sec -= 1
    }, 1000)
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    this.timer = null
  }
}
</script>

<style lang="less">
  .step2{
    .flex;
    .edit-email{
      width: 100%;
    }
    .send_success{
      .flex(@direction: column);
      padding-top: 130px;
      .complete-img{
        width: 160px;
        height: 181px;
      }
      h3{
        margin: 40px 0 30px;
        font-size: 36px;
        color: @black;
      }
      p{
        font-size: 26px;
        color: @grey;
      }
      .next-btn{
        width: 280px;
        height: 78px;
      }
    }
  }
</style>
