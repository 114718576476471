<template>
  <div class="step3">
    <van-form>
      <van-field
        v-model="form.company"
        name="企业"
        left-icon="company"
        label=""
        placeholder="请输入企业名称"
        autocomplete="off"
        :required="false"
        :rules="[{ required: true, message: '请输入企业名称' }]"
      >
        <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="company"></van-icon>
      </van-field>
      <van-field
        v-model="form.name"
        name="姓名"
        left-icon="user"
        label=""
        placeholder="请输入姓名"
        autocomplete="off"
        :rules="[{ required: true, message: '请输入姓名' }]"
      >
        <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="user"></van-icon>
      </van-field>
      <div class="address">
        <div class="address-item">
          <van-field
            v-model="form.province"
            name="省"
            label=""
            placeholder="请选择省"
            readonly
            @click="handlePopup"
            :rules="[{ required: true, message: '请选择省' }]"
          >
          </van-field>
        </div>
        <div class="address-item">
          <van-field
            v-model="form.city"
            name="市"
            label=""
            placeholder="请选择市"
            readonly
            @click="handlePopup"
            :rules="[{ required: true, message: '请选择市' }]"
          >
          </van-field>
        </div>
      </div>
      <van-field
        v-model="form.email"
        name="邮箱"
        left-icon="email"
        label=""
        placeholder="请输入邮箱"
        autocomplete="off"
        :rules="[{ required: false, validator: regEmailFunc, message: '请输入正确的邮箱' }]"
      >
        <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="email"></van-icon>
      </van-field>
      <van-field
        v-model="form.position"
        name="公司职位"
        left-icon="solution"
        label=""
        placeholder="请输入公司职位"
        autocomplete="off"
        :rules="[{ required: false, message: '请输入公司职位' }]"
      >
        <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="solution"></van-icon>
      </van-field>
      <div class="submit">
        <van-button round block
                    type="info"
                    @click="handleSignup"
                    :loading="loading"
                    :disabled="!form.company || !form.name || !form.province || !form.city"
        >
          完成注册
        </van-button>
      </div>
    </van-form>
    <van-popup v-model="showPop" position="bottom">
      <van-area
        title="所在地"
        :area-list="areaList"
        :columns-num="2"
        @confirm="onConfirm"
        @cancel="onCancel"
        :columns-placeholder="['请选择', '请选择']"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import pageMixin from '@/mixins/pageMixin'
import accountMixin from '@/mixins/accountMixin'
import areaList from '@/assets/area'
export default {
  mixins: [pageMixin, accountMixin],
  data () {
    return {
      areaList,
      form: {
        company: '',
        name: '',
        province: '',
        city: '',
        email: '',
        position: ''
      },
      cityArr: [],
      curProvinceCode: '',
      loading: false,
      showPop: false
    }
  },
  methods: {
    ...mapActions(['signup']),
    regEmailFunc (e) {
      if (!e) {
        return true
      } else {
        return this.regEmail.test(e)
      }
    },
    handlePopup () {
      this.showPop = true
    },
    onConfirm (value) {
      if (!value[0].code || !value[1] || !value[1].code) {
        this.$toast({
          message: '请选择完整地址',
          duration: 800
        })
        return
      }
      this.form.province = value[0].name
      this.form.city = value[1].name
      this.showPop = false
    },
    onCancel () {
      this.showPop = false
    },
    async handleSignup () {
      const params = Object.assign({}, this.$parent.form, this.form)
      params.uniqueStr = this.$route.query.guid ? this.$route.query.guid : ''
      this.loading = true
      try {
        await this.signup(params)
      } catch (e) {
        return false
      } finally {
        this.loading = false
      }
      this.$emit('nextStep')
    }
  }
}
</script>

<style lang="less" scoped>
  .step3{
    .address{
      .flex(@justify: space-between);
      .address-item{
        width: 47%;
      }
    }
    .icon-solution{
      font-size: 42px;
      margin-right: 3px;
    }
    /deep/ .van-cell--required::before{
      left: 90px;
      top: 20px;
      font-size: 18px;
    }
    /deep/ .van-picker{
      .van-picker__toolbar{
        height: auto;
        padding: 20px 0;
      }
      .van-picker-column,.van-picker__cancel,.van-picker__confirm,.van-picker__title{
        font-size: 28px;
        line-height: 28px;
      }
    }
  }
</style>
