import { codeDuration } from '@/utils/variable'
import { regPhone, regSmsCode, regEmail, regName } from '@/utils/regexp'
export default {
  components: {
  },
  data () {
    return {
      regPhone,
      regSmsCode,
      regName,
      regEmail,
      timer: null,
      currTime: 0
    }
  },
  computed: {
    codeText () {
      return this.currTime > 0 ? this.currTime + 's' : '获取验证码'
    },
    codeDisable () {
      return !(this.regPhone.test(this.form.phone)) || this.timer
    }
  },
  methods: {
    _getSubmitDisabled () {
      return Object.entries(this.form).some(item => {
        const type = item[0].charAt(0).toUpperCase() + item[0].slice(1)
        return !this[`reg${type}`].test(item[1])
      })
    },
    _startCodeTimer () {
      this.currTime = codeDuration
      this.timer = setInterval(() => {
        this.currTime -= 1
        if (this.currTime === 0) {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    this.timer = null
  }
}
