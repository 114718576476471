<template>
  <div class="step1">
    <van-form>
      <van-field
        v-model="form.phone"
        type="tel"
        name="手机号"
        left-icon="phone"
        label="+86"
        placeholder="请输入手机号"
        autocomplete="off"
        @click-input="handleFieldClick"
        autofocus="autofocus"
        :rules="[{ required: true,pattern: regPhone, message: '请输入正确的手机号' }]"
      >
        <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="phone"></van-icon>
      </van-field>
      <van-field
        v-model="form.smsCode"
        type="digit"
        name="验证码"
        left-icon="code"
        label=""
        placeholder="请输入验证码"
        autocomplete="off"
        maxlength="6"
        @click-input="handleFieldClick"
        :rules="[{ required: true,pattern: regSmsCode, message: '请输入正确的验证码' }]"
      >
        <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="code"></van-icon>
        <template #button>
          <div class="get-code"
               :class="{disabled: codeDisable}"
               @click="handleGetCode"
          >{{codeText}}
          </div>
        </template>
      </van-field>
      <div class="accept">
        <van-checkbox v-model="accept">
          我已阅读并同意
          <a :href="`${publicPath}virtai_tech_user_agreement.pdf`" target="_blank">《用户协议》</a><a :href="`${publicPath}virtai_tech_privacy_statement.pdf`" target="_blank">《隐私声明》</a>
        </van-checkbox>
      </div>
      <div class="submit">
        <van-button round block
                    type="info"
                    @click="handleNext"
                    :loading="loading"
                    :disabled="_getSubmitDisabled()"
        >
          下一步
        </van-button>
      </div>
      <p class="to-signin">
        <span>已有账号?</span>
        <router-link to="/account/signin">直接登录</router-link>
      </p>
    </van-form>
  </div>
</template>

<script>
import { publicPath } from '@/utils/variable'
import pageMixin from '@/mixins/pageMixin'
import accountMixin from '@/mixins/accountMixin'
export default {
  mixins: [pageMixin, accountMixin],
  data () {
    return {
      form: {
        phone: '',
        smsCode: ''
      },
      publicPath,
      accept: false,
      loading: false
    }
  },
  methods: {
    handleFieldClick (e) {
      e.target.focus()
    },
    async handleNext () {
      if (!this.accept) {
        this.$toast.fail('请阅读并同意《服务协议》')
        return false
      }
      this.loading = true
      this.api.validateSms(this.form).then(async data => {
        this.$emit('nextStep', {
          form: this.form
        })
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false
      })
    },
    async handleGetCode () {
      if (this.codeDisable) return false
      this.loading = true
      const { phone } = this.form
      try {
        await this.api.getSms(phone)
        this._startCodeTimer()
      } catch (e) {
        return false
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .van-field {
    margin-top: 50px;
  }
  .to-signin{
    display: inline-block;
    margin-top: 32px;
    font-size: 28px;
    color: @blue;
    span{
      color: @grey2;
    }
  }
  .submit{
    margin-top: 144px;
  }
  .accept{
    margin-top: 53px;
    font-size: 28px;
    white-space: nowrap;
    /deep/ .van-checkbox__icon{
      margin-right: 14px;
      font-size: 30px;
    }
    /deep/ .van-checkbox__label{
      line-height: 32px;
    }
    a{
      position: relative;
      z-index: 1;
      color: @blue;
    }
  }
</style>
