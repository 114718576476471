<template>
  <div class="signup">
    <div class="menu" @click="handleMenu">
      <i class="icon iconfont icon-menu"></i>
    </div>
    <hgroup>
      <h2>新用户注册</h2>
      <h3>{{stepInfo[currentStep].text}}
        <!--<span v-if="currentStep === 1 && verifyStatus === 0">-请填写您的企业邮箱，以便于快速通过产品试用/购买申请审核</span>-->
      </h3>
    </hgroup>
    <ol class="sign-steps">
      <li v-for="(item, index) of stepInfo" :key="item.icon" :class="[`step${index+1}`, {current: index === currentStep, finish: currentStep > index}]">
        <div class="step-item">
          <i class="step-icon iconfont" :class="[`icon-${stepInfo[index].icon}`, {'icon-confirm': currentStep > index}]"></i>
        </div>
      </li>
    </ol>
    <div class="signup_form_container">
      <step1 v-if="currentStep === 0" @nextStep="nextStep"/>
      <!--<step2 v-if="currentStep === 1" :verifyStatus="verifyStatus" @nextStep="nextStep"/>-->
      <step3 v-if="currentStep === 1" @nextStep="nextStep"/>
      <step4 v-if="currentStep === 2" @finish="finish"/>
    </div>
    <c-menu :showMenu="showMenu" @closeMenu="_asyncClosePop"></c-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Step1 from '@/components/signup/step1'
// import Step2 from '@/components/signup/step2'
import Step3 from '@/components/signup/step3'
import Step4 from '@/components/signup/step4'
import menu from '~/components/menu'
import pageMixin from '@/mixins/pageMixin'
export default {
  mixins: [pageMixin],
  components: {
    Step1,
    // Step2,
    Step3,
    Step4,
    cMenu: menu
  },
  data () {
    return {
      stepInfo: [
        {
          icon: 'phone',
          text: '验证手机号'
        },
        // {
        //   icon: 'email',
        //   text: '验证邮箱'
        // },
        {
          icon: 'info',
          text: '完善信息'
        },
        {
          icon: 'finish',
          text: '注册完成'
        }
      ],
      currentStep: 0,
      // 邮箱是否已验证 0-未验证 1-验证成功 2-验证失败
      verifyStatus: 0,
      form: {},
      showMenu: false
    }
  },
  watch: {
    $route (to, from) {
      location.reload()
    }
  },
  methods: {
    ...mapActions(['signup']),
    async validateEmail () {
      const guid = this.$route.query.guid
      this.api.validateEmail(guid).then(async data => {
        this.verifyStatus = 1
        this.currentStep = 1
        this.form = Object.assign({}, data, this.form)
      }).catch(error => {
        if (error === -19) {
          this.verifyStatus = 2
          this.currentStep = 1
        } else {
          this.currentStep = 0
        }
      })
    },
    nextStep (params = {}) {
      // console.log(params)
      if (this.currentStep === 0) {
        const { form = {} } = params
        Object.assign(this.form, form)
      }
      if (this.currentStep < 2) {
        this.currentStep += 1
      }
    },
    finish () {
      const { redirect = '/' } = this.$route.query || {}
      this.$router.replace(redirect)
    },
    handleMenu () {
      this.showMenu = true
    },
    _asyncClosePop () {
      setTimeout(() => {
        this.showMenu = false
      }, 150)
    }
  },
  created () {
    // if (JSON.stringify(this.$route.query) !== '{}' && this.$route.query.guid) {
    //   this.validateEmail()
    // }
  }
}
</script>

<style lang="less" scoped>
  .signup{
    position: relative;
    padding: 120px 60px 300px;
    hgroup{
      margin-bottom: 90px;
      h2{
        color: @black;
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 25px;
      }
      h3{
        color: @grey;
        font-size: 26px;
        line-height: 34px;
      }
    }
    .sign-steps{
      .flex(@justify: space-between);
      margin-bottom: 80px;
      li{
        position: relative;
        &::after{
          content: '';
          position: absolute;
          left: 105px;
          top: 50%;
          display: block;
          width: 150px;
          height: 2px;
          background: #E1E7F0;
          transform: translateY(-50%);
        }
        &:last-child{
          &::after{
            display: none;
          }
        }
        .step-item{
          width: 90px;
          height: 90px;
        }
        .step-icon{
          position: relative;
          .flex;
          width: 100%;
          height: 100%;
          display: block;
          border: 1px #E1E7F0 solid;
          border-radius: 50%;
          transition: .3s;
          &.icon-email{
            font-size: 32px;
          }
          &.icon-confirm{
            font-size: 28px;
          }
          &::before{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.current{
          .step-icon{
            background: @blue;
            color: #fff;
          }
        }
        &.finish{
          &::after{
            background: @blue;
          }
          .step-icon{
            border-color: @blue;
            color: @blue;
          }
        }
      }
    }
    .menu{
      position: absolute;
      right: 60px;
      .icon{
        display: block;
        opacity: .8;
        transition: .3s;
        border-radius: 2px;
        color: @grey;
        font-size: 28px;
      }
    }
  }
</style>
